import { Injectable } from '@angular/core';
import { Observable, Subject, catchError, map, of, switchMap } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';

@Injectable({
    providedIn: 'root',
})
export class TelemetryService {
    
  constructor( private http: HttpClient) {}
  
    logException(error, method): Observable<string> {
      try {
        const data = {
          status_code: error?.status,
          ip: '',
          exception_message: error?.error?.error,
          is_mobile: false,
          timestamp: new Date().toISOString(),
          http_method: method,
          request_path: error?.url,
          user_agent: navigator.userAgent,
          referrer: document.referrer
        }

        return this.getIpAddress().pipe(
          switchMap(ip => {
            data.ip = ip;
            const url = `${environment.baseUrl}logException`;
            return this.http.post<any>(url, data);
          }),
          map(response => response),
          catchError(error => {
            console.error('Error in TelemetryService API:', error);
            return of(null);
          })
        );
      } catch (error) {
        console.log("Error in TelemetryService API call:", error)
        return of(null);
      }
  }
  
  private getIpAddress(): Observable<string> {
    return this.http.get('https://api.ipify.org?format=json').pipe(
      map((response: any) => response.ip),
      catchError(error => {
        console.error('Error fetching IP address:', error);
        return of('');
      })
    );
  }
}